























































































import AuthUtils from '@/utils/AuthUtils';
import LocaleSelector from '@/components/locale/LocaleSelector.vue';
import Vue from 'vue';

export default Vue.extend({
  components: {
    LocaleSelector,
  },
  data: (): {
    state: {
      visible: boolean;
      validToken: boolean;
      valid: boolean;
      submit: boolean;
    };
    showPassword: boolean;
    password: string;
    confirmation: string;
    errorCode?: string;
  } => ({
    state: {
      visible: false,
      validToken: false,
      valid: false,
      submit: false,
    },
    showPassword: false,
    password: '',
    confirmation: '',
    errorCode: '',
  }),

  computed: {
    token() {
      return this.$route.query.t as string;
    },
    passwordsMatch() {
      return this.confirmation == this.password
        ? true
        : this.$t('password.do_not_match');
    },
    title() {
      return this.$langobj.parse(JSON.parse(process.env.VUE_APP_TITLE));
    },
  },

  async mounted() {
    try {
      await AuthUtils.api.validateToken(this.token);
      this.state.validToken = true;
    } catch (error) {
      this.state.validToken = false;
    } finally {
      this.state.visible = true;
    }
  },

  methods: {
    async handleSubmit() {
      try {
        this.state.submit = true;
        await AuthUtils.api.passwordChange(this.token, this.password);
        this.$toast.push({
          text: this.$t('auth.password.change.success'),
          type: 'success',
        });
        this.$router.replace({ name: 'home' });
      } catch (error: any) {
        this.errorCode = error.response.data.message;
        console.log(error);
      } finally {
        this.state.submit = false;
      }
    },
  },
});
